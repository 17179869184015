import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import CloseButton from '../close-button';
import {badgeStyles} from './styles';
import type {DynamicComponentProps} from '../../types';
import type {BadgeVariantProps} from './styles';

export type BadgeProps = Omit<DynamicComponentProps, 'prefix'> &
	BadgeVariantProps & {
		disabled?: boolean;
		prefix?: ReactNode;
		suffix?: ReactNode;
		dismissable?: boolean;
		onDismiss?: () => void;
	};
const Badge = forwardRef<HTMLElement, BadgeProps>(
	(
		{
			as: Component = 'div',
			prefix,
			suffix,
			dismissable,
			size,
			color,
			interactive,
			onDismiss,
			disabled,
			children,
			className,
			...props
		},
		forwardedRef,
	) => (
		<Component
			{...props}
			className={twMerge(badgeStyles({size, color, interactive, disabled}), className)}
			disabled={disabled}
			ref={forwardedRef}
			tabIndex={interactive ? 0 : undefined}
		>
			{prefix}
			{children}
			{suffix}
			{dismissable && <CloseButton circular color={color} onPress={onDismiss} size="xs" />}
		</Component>
	),
);

Badge.displayName = 'Badge';

export default Badge;

export * from './styles';
