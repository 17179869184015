import {featureGifMap} from '~/app/(standard-layout)/_/constants';
import {Accordion, type AccordionItemProps, AspectRatio} from '@job-ish/ui/components';
import {AnimatePresence, m} from 'framer-motion';

import type {Icon} from '@job-ish/ui/types';
import {FeaturesIcon} from './icon';

const MotionAspectRatio = m.create(AspectRatio);

export type FeaturesItem = Omit<AccordionItemProps, 'children' | 'trigger' | 'value'> & {
	icon: Icon;
	title: string;
	description: string;
};

export const FeaturesItem = ({icon, title, description, ...props}: FeaturesItem) => (
	<Accordion.Item
		{...props}
		className="md:max-w-sm lg:max-w-md [&>*>*]:py-4"
		trigger={
			<div className="flex items-center justify-start gap-4">
				<FeaturesIcon icon={icon} />
				<div className="truncate font-mono text-lg font-semibold text-mauve12">{title}</div>
			</div>
		}
		value={title}
	>
		<p>{description}</p>
		<div className="block md:hidden">
			<AnimatePresence mode="wait">
				<MotionAspectRatio
					animate={{opacity: 1, scale: 1}}
					className="m-auto mt-4 overflow-hidden rounded-md"
					exit={{opacity: 0}}
					initial={{opacity: 0, scale: 0.9}}
					key={featureGifMap[title as keyof typeof featureGifMap]}
					ratio={16 / 9}
				>
					<div className="absolute h-full w-full rounded-md bg-gradient-to-br from-violet11 to-plum11 blur-sm" />
					<video
						autoPlay
						className="relative z-[1] mx-auto block h-auto min-h-full w-auto min-w-full rounded-md p-1 md:hidden"
						controls={false}
						height={854}
						loop
						muted
						playsInline
						preload="none"
						width={480}
					>
						<source
							src={featureGifMap[title as keyof typeof featureGifMap] || '/videos/job-ish_kanban.mp4'}
							type="video/mp4"
						/>
						{/* Dummy track element for captions */}
						<track default kind="captions" label="English" lang="en" />
					</video>
				</MotionAspectRatio>
			</AnimatePresence>
		</div>
	</Accordion.Item>
);
