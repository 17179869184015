import {useState} from 'react';
import {featureGifMap} from '~/app/(standard-layout)/_/constants';
import {Accordion, AspectRatio, Card} from '@job-ish/ui/components';
import {
	IconFileTextAi,
	IconFilter,
	IconGraph,
	IconIdBadge2,
	IconLayoutKanban,
	IconMailBolt,
} from '@tabler/icons-react';
import {AnimatePresence, m} from 'framer-motion';

import {FeaturesCard} from './card';
import {FeaturesItem} from './item';

const MotionAspectRatio = m.create(AspectRatio);

export const Features = () => {
	const [activeFeature, setActiveFeature] = useState<keyof typeof featureGifMap>('Kanban Style');

	return (
		<div className="px-4" id="features">
			<m.div
				className="mx-auto max-w-4xl pb-1 text-center text-3xl font-semibold text-mauve12 sm:text-4xl"
				variants={{visible: {opacity: 1, y: 0}, hidden: {opacity: 0, y: -15}}}
			>
				<span className="mr-2 bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
					Powerful
				</span>
				features to help you land your
				<span className="mx-2 bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
					dream
				</span>
				job.
				<p className="pt-2 text-base text-mauve11">
					We&apos;re defining the standard for the modern job search.
				</p>
			</m.div>
			<Card bordered className="mx-auto mt-12 max-w-7xl" shadow>
				<Card.Body className="flex flex-wrap items-center gap-8">
					<Accordion
						className="grow md:w-fit md:grow-0"
						onValueChange={value => setActiveFeature(value as keyof typeof featureGifMap)}
						type="single"
						value={activeFeature}
					>
						<FeaturesItem
							description="Track your job applications in a visual way. Simply drag and drop to update statuses - the ultimate job search sidekick."
							icon={IconLayoutKanban}
							title="Kanban Style"
						/>
						<FeaturesItem
							description="Share your resume, socials, and more with a single link. Never waste time building a portfolio again."
							icon={IconIdBadge2}
							title="Personal Page Builder"
						/>
						<FeaturesItem
							description="Never write another cover letter again. Generate a cover letter tailored to any job in seconds."
							icon={IconFileTextAi}
							title="Cover Letter Generator"
						/>

						<FeaturesItem
							description="Keep a pulse on your progress and compare the performance of different resume versions so you know what's working."
							icon={IconGraph}
							title="Analytics Dashboard"
						/>
						<FeaturesItem
							description="Only wanna see remote jobs you've applied for? You can create a view for that."
							icon={IconFilter}
							title="Custom Views"
						/>
					</Accordion>
					<div className="hidden grow md:block">
						<AnimatePresence mode="wait">
							<MotionAspectRatio
								animate={{opacity: 1, scale: 1}}
								className="m-auto overflow-hidden rounded-md"
								exit={{opacity: 0}}
								initial={{opacity: 0, scale: 0.9}}
								key={featureGifMap[activeFeature]}
								ratio={16 / 9}
							>
								<div className="absolute h-full w-full rounded-md bg-gradient-to-br from-violet11 to-plum11 blur-sm" />
								<video
									autoPlay
									className="relative z-[1] mx-auto h-full w-full rounded-md object-fill p-1"
									controls={false}
									height={1280}
									loop
									muted
									playsInline
									preload="none"
									width={720}
								>
									<source
										src={featureGifMap[activeFeature] || '/videos/job-ish_kanban.mp4'}
										type="video/mp4"
									/>
									{/* Dummy track element for captions */}
									<track default kind="captions" label="English" lang="en" />
								</video>
							</MotionAspectRatio>
						</AnimatePresence>
					</div>
				</Card.Body>
			</Card>
			<m.div
				className="mx-auto mb-8 mt-16 max-w-4xl pb-1 text-center text-3xl font-semibold text-mauve12 sm:text-4xl"
				variants={{visible: {opacity: 1, y: 0}, hidden: {opacity: 0, y: -15}}}
			>
				...and
				<span className="mx-2 bg-gradient-to-br from-plum11 to-violet11 bg-clip-text text-transparent">
					we&apos;re just getting started.
				</span>
				<p className="pt-2 text-base text-mauve11">
					We&apos;re constantly adding new features to help you land your dream job.
				</p>
			</m.div>
			<div className="flex flex-wrap items-center justify-center gap-4">
				<FeaturesCard
					description="Get notified when new jobs are posted that match your filters and preferences."
					icon={IconMailBolt}
					planned
					title="Email Notifications"
				/>
			</div>
		</div>
	);
};
