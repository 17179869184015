import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {PropsWithChildren} from 'react';
import Paper from '../paper';
import {cardStyles} from './styles';
import type {DynamicComponentProps} from '../../types';
import type {PaperProps} from '../paper';
import type {CardVariantProps} from './styles';

export type CardTitleProps = DynamicComponentProps;
const CardTitle = forwardRef<HTMLElement, CardTitleProps>(
	({as: Component = 'h2', className, ...props}, forwardedRef) => (
		<Component
			{...props}
			className={twMerge('font-semibold leading-none tracking-tight', className)}
			ref={forwardedRef}
		/>
	),
);

export type CardDescriptionProps = DynamicComponentProps;
const CardDescription = forwardRef<HTMLElement, CardDescriptionProps>(
	({as: Component = 'p', className, ...props}, forwardedRef) => (
		<Component
			{...props}
			className={twMerge('text-sm leading-tight text-mauve11', className)}
			ref={forwardedRef}
		/>
	),
);

export type CardHeaderProps = DynamicComponentProps;
const CardHeader = forwardRef<HTMLElement, CardHeaderProps>(
	({as: Component = 'div', className, ...props}, forwardedRef) => (
		<Component
			{...props}
			className={twMerge('flex items-center justify-between gap-2 text-mauve12', className)}
			ref={forwardedRef}
		/>
	),
);

export type CardBodyProps = DynamicComponentProps;
const CardBody = forwardRef<HTMLElement, CardBodyProps>(
	({as: Component = 'div', className, ...props}, forwardedRef) => (
		<Component {...props} className={twMerge('text-base text-mauve11', className)} ref={forwardedRef} />
	),
);

export type CardFooterProps = DynamicComponentProps;
const CardFooter = forwardRef<HTMLElement, CardFooterProps>(
	({as: Component = 'div', className, ...props}, forwardedRef) => (
		<Component {...props} className={twMerge('flex items-center gap-2', className)} ref={forwardedRef} />
	),
);

export type CardProps = PropsWithChildren<PaperProps & CardVariantProps & DynamicComponentProps>;
const Card = Object.assign(
	forwardRef<HTMLElement, CardProps>(({as: Component = 'div', className, ...props}, forwardedRef) => (
		<Paper {...props} as={Component} className={twMerge(cardStyles(), className)} ref={forwardedRef} />
	)),
	{Header: CardHeader, Body: CardBody, Footer: CardFooter, Description: CardDescription, Title: CardTitle},
);

Card.displayName = 'Card';
Card.Header.displayName = 'Card.Header';
Card.Body.displayName = 'Card.Body';
Card.Footer.displayName = 'Card.Footer';
Card.Description.displayName = 'Card.Description';
Card.Title.displayName = 'Card.Title';

export default Card;

export * from './styles';
