import {Accordion, Anchor} from '@job-ish/ui/components';
import {m} from 'framer-motion';

export const FAQ = () => (
	<Accordion
		className="mx-auto flex w-full max-w-full grow flex-col p-4 sm:max-w-4xl sm:grow-0"
		id="faq"
		type="multiple"
	>
		<m.div
			className="mx-auto max-w-4xl px-4 pb-12 text-center text-3xl font-semibold text-mauve12 sm:text-4xl"
			variants={{visible: {opacity: 1, y: 0}, hidden: {opacity: 0, y: -15}}}
		>
			Got questions?
			<p className="pt-2 text-base text-mauve11">We&apos;ve got answers.</p>
		</m.div>
		<Accordion.Item size="lg" trigger="What is job-ish?" value="what-is-job-ish">
			<b>job-ish</b> is a tool that helps take the pain out of the job search process. It&apos;s a centralized
			place to organize, track, and optimize all of your job applications. Our goal is to help you get hired
			faster and accelerate your career.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="Is the job board free?" value="is-the-job-board-free">
			Yes! The job board is free to use - a small percentage of jobs are only visible to premium accounts. You
			can start exploring job listings right now by visiting{' '}
			<Anchor className="inline" href="https://app.job-ish.com">
				app.job-ish.com
			</Anchor>
			.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="Where does the name come from?" value="where-does-the-name-come-from">
			We&apos;re tired of all the <em>ish</em> involved in the job search, and we wanted to make it easier to
			get hired (we also wanted to give your spreadsheets a break). Optimize, organize, and track your job
			search with <b>job-ish</b>.
		</Accordion.Item>
		<Accordion.Item
			size="lg"
			trigger="Why would I buy an annual subscription?"
			value="why-would-i-buy-an-annual-subscription"
		>
			You shouldn&apos;t. <b>job-ish</b> is designed to help you get hired faster and an annual subscription
			is counterintuitive to that goal. You should only pay for an annual plan if you want to support the
			development of the product and the team behind it.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="Why was the free trial period removed?" value="free-trial-removed">
			We operate at a significant loss and want to ensure that we can continue to provide the best possible
			service to our users. By removing the free trial, we can focus on improving the product and adding new
			features without compromising our commitment to an ad-free and privacy-friendly experience to our users.
		</Accordion.Item>
		<Accordion.Item
			size="lg"
			trigger="When will my credit card be charged?"
			value="when-will-my-credit-card-be-charged"
		>
			Since we don&apos;t require a credit card to sign up, we won&apos;t charge your card until you select a
			subscription plan after signing up.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="What is your refund policy?" value="what-is-your-refund-policy">
			We don&apos;t currently offer refunds, but you can cancel your subscription at any time and you
			won&apos;t be charged again.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="Does job-ish work on mobile?" value="does-job-ish-work-on-mobile">
			<b>job-ish</b> is optimized for both mobile and desktop devices. Take your job search with you wherever
			you go.
		</Accordion.Item>
		<Accordion.Item size="lg" trigger="What if I have more questions?" value="what-if-i-have-more-questions">
			Feel free to reach out to us at{' '}
			<Anchor className="inline" href="mailto:help@job-ish.com">
				help@job-ish.com
			</Anchor>{' '}
			and we&apos;ll get back to you.
		</Accordion.Item>
	</Accordion>
);
