import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const badgeStyles = cva(
	[
		'flex w-fit items-center justify-center font-medium leading-4 focus-visible:outline',
		'tracking-wide gap-1 whitespace-nowrap shrink-0 border rounded-full',
	],
	{
		variants: {
			size: {
				xs: 'px-2 py-0 text-[10px] leading-3',
				sm: 'px-2 py-0 text-xs',
				md: 'py-0.5 px-2.5 text-sm',
				lg: 'py-1 px-3 text-sm',
			},
			color: {
				neutral: 'text-mauve11 bg-mauve3 border-mauve7',
				primary: 'text-violet11 bg-violet3 border-violet7',
				secondary: 'text-plum11 bg-plum3 border-plum7',
				success: 'text-green11 bg-green3 border-green7',
				warning: 'text-yellow11 bg-yellow3 border-yellow7',
				danger: 'text-red11 bg-red3 border-red7',
				info: 'text-blue11 bg-blue3 border-blue7',
			},
			interactive: {
				true: 'select-none cursor-pointer focus-visibile:outline',
				false: 'focus-visible:outline-none',
			},
			disabled: {true: 'opacity-40 pointer-events-none'},
		},
		compoundVariants: [
			{
				color: 'neutral',
				interactive: true,
				className: 'hover:bg-mauve4 hover:border-mauve8 focus-visible:outline-mauve8',
			},
			{
				color: 'primary',
				interactive: true,
				className: 'hover:bg-violet4 hover:border-violet8 focus-visible:outline-violet8',
			},
			{
				color: 'secondary',
				interactive: true,
				className: 'hover:bg-plum4 hover:border-plum8 focus-visible:outline-plum',
			},
			{
				color: 'success',
				interactive: true,
				className: 'hover:bg-green4 hover:border-green8 focus-visible:outline-green8',
			},
			{
				color: 'warning',
				interactive: true,
				className: 'hover:bg-yellow4 hover:border-yellow8 focus-visible:outline-yellow8',
			},
			{
				color: 'danger',
				interactive: true,
				className: 'hover:bg-red4 hover:border-red8 focus-visible:outline-red8',
			},
			{
				color: 'info',
				interactive: true,
				className: 'hover:bg-blue4 hover:border-blue8 focus-visible:outline-blue8',
			},
		],
		defaultVariants: {size: 'md', color: 'neutral', interactive: false, disabled: false},
	},
);

export type BadgeVariantProps = VariantProps<typeof badgeStyles>;
